<template>
  <div class="calendrier">
    <Header title="Agenda" app />
    <div class="container">
      <Navbar />
      <div class="loader" v-if="!user">
        <Spinner />
      </div>
      <EmptyState
        v-else-if="user.events.length == 0 || user.agenda.length == 0"
      />
      <div v-else class="agenda__container">
        <div class="agenda__nav">
          <p
            @click="agendaNavHandler"
            :class="[agendaPage === 'agenda' ? 'active' : '']"
          >
            Agenda
          </p>
          <p
            @click="historiqueNavHandler"
            :class="[agendaPage === 'historique' ? 'active' : '']"
          >
            Historique
          </p>
        </div>
        <div
          class="agenda__box"
          v-if="agendaPage === 'agenda' || agendaPage === 'desktop'"
        >
          <Calendar :attributes="events" is-expanded />
          <div class="agenda">
            <h3>Rendez-vous</h3>
            <Card
              v-for="rdv in getFuturAgenda(user.agenda)"
              :key="rdv.id"
              :event="rdv"
              :isRDV="true"
              @clicked="agendaHandler(rdv)"
            />
          </div>
        </div>
        <div
          class="agenda__box"
          v-if="agendaPage === 'historique' || agendaPage === 'desktop'"
        >
          <h3>Historique</h3>
          <Card
            v-for="rdv in getPastAgenda(user.agenda)"
            :key="rdv.id"
            :event="rdv"
            :isRDV="true"
            @clicked="agendaHandler(rdv)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../components/Navbar.vue";
import Calendar from "v-calendar/lib/components/calendar.umd";
import Spinner from "../components/Spinner.vue";
import Card from "../components/Card.vue";
import Header from "../components/Header.vue";
import EmptyState from "../components/EmptyState.vue";

export default {
  components: {
    Navbar,
    Calendar,
    Spinner,
    Card,
    Header,
    EmptyState
  },
  data() {
    return {
      agendaPage: window.innerWidth > 850 ? "desktop" : "agenda"
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    events() {
      const user = this.$store.getters.user;

      const attr = [];

      if (user) {
        const agenda = user.agenda;

        agenda.forEach(el => {
          const date = el.date.split("-");
          attr.push({
            key: el.type,
            highlight: {
              color:
                new Date(date[0], date[1] - 1, date[2]) > new Date()
                  ? "red"
                  : "gray",
              fillMode: "solid",
              contentClass: "italic"
            },
            popover: {
              label: el.type,
              visibility: "hover",
              hideIndicator: true
            },
            dates: new Date(date[0], date[1] - 1, date[2])
          });
        });
      }
      attr.push({
        key: "today",
        dot: "blue",
        dates: new Date()
      });
      return attr;
    }
  },
  methods: {
    agendaHandler(event) {
      this.$store.dispatch("popupAgendaOpen", event);
    },
    onResize() {
      if (window.innerWidth > 850) {
        this.agendaPage = "desktop";
      } else {
        this.agendaPage = "agenda";
      }

      console.log(this.agendaPage);
    },
    getFuturAgenda(arr) {
      return arr.filter(el => {
        return this.remainingDays(el.date) > 0;
      });
    },
    getPastAgenda(arr) {
      return arr.filter(el => {
        return this.remainingDays(el.date) <= 0;
      });
    },
    remainingDays(date) {
      const date1Array = new Date()
        .toJSON()
        .slice(0, 10)
        .split("-")
        .reverse();
      const date2Array = date.split("-");
      const date1 = new Date(date1Array[2], date1Array[1] - 1, date1Array[0]);
      const date2 = new Date(date2Array[0], date2Array[1] - 1, date2Array[2]);
      console.log(date2Array);
      const one_day = 1000 * 60 * 60 * 24;
      return Math.ceil((date2.getTime() - date1.getTime()) / one_day);
    },
    agendaNavHandler() {
      this.agendaPage = "agenda";
    },
    historiqueNavHandler() {
      this.agendaPage = "historique";
    }
  }
};
</script>

<style lang="scss">
h3 {
  text-transform: uppercase;
  font-size: 20px;
  margin-bottom: 20px;
  text-align: left;
}
.vc-container {
  --red-600: #95cdcb !important;
  border-color: #fff;
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.13);
  border-radius: 20px;
}
.vc-header {
  height: 70px;
  position: relative;
  margin-bottom: 20px;

  &::before {
    content: "";
    width: 100%;
    height: 1px;
    background-color: #e1e3eb;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
.vc-arrows-container {
  top: 40px !important;
  transform: translateY(-50%);
}
.vc-svg-icon path {
  fill: #95cdcb !important;
}
.vc-title {
  text-transform: uppercase;
  font-size: 16px !important;
  color: #5d6770 !important;
  font-weight: normal !important;
}

.agenda {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  text-align: left;
  margin-top: 30px;
  cursor: pointer;

  &__container {
    margin-top: 40px;
    text-align: left;
  }
  &__nav {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    padding: 10px;
    background-color: #95cdcb;
    margin-bottom: 10px;
    border-radius: 5px;
    color: #fff;
    align-items: center;
    text-transform: uppercase;
    margin-bottom: 20px;
    .active {
      background-color: #75acaa;
      padding: 10px 30px;
      border-radius: 18px;
    }
  }
}
@media (min-width: 850px) {
  .agenda {
    &__container {
      display: flex;
      column-gap: 150px;
      flex-direction: row;
      justify-content: flex-start;
      align-content: center;
      height: 100%;
      width: 90%;
      margin: auto;
    }
    &__box {
      width: 40%;
      h3 {
        margin-top: 0px;
      }
    }
    &__nav {
      display: none;
    }
  }
}
</style>
